import {Injectable} from '@angular/core';
import {SignalService} from '@shared/core/services/signal.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private signalService: SignalService) {
  }

  saveAuthorities(authorities: {authority: string}[]): void {
    this.signalService.authorities.set(authorities.map(el => el.authority));
  }

  isPermitted(permission: string): boolean {
    return this.signalService.authorities().includes(permission);
  }
}
